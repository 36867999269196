body {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.company_icon {
  /* background: #e9e9e9; */
  padding: 1px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.company_icon2 {
  /* background: #e9e9e9; */
  padding: 1px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.m-marketing__asFeaturedIn {
  margin: 78px 0px!important;
  
}

.m-marketing__asFeaturedIn2 {
  margin-bottom: 20px;
  
}

@media(max-width:500px){
  .m-marketing__asFeaturedIn2 {
    margin-bottom: 20px;
    margin-top: 0px;
    
  }
}

.m-marketing__asFeaturedIn {
  width: 90%;
}
.m-marketing__asFeaturedIn2 {
  width: 100%;
}

.m-marketing__asFeaturedIn ul {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}

.m-marketing__asFeaturedIn2 ul {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}


.m-marketing__asFeaturedIn ul>li img {
  width: 86px;
  height: 55px;
  /* -o-object-fit: contain; */
  object-fit: contain;
}

.m-marketing__asFeaturedIn2 ul>li img {
  width: 86px;
  height: 55px;
  /* -o-object-fit: contain; */
  object-fit: contain;
}
.m-marketing__asFeaturedIn ul>li{
 width: 200px !important;
 height: 150px !important;
}

.m-marketing__asFeaturedIn2 ul>li{
  width: 200px !important;
  height: 150px !important;
 }

.liDiv{
  width: 200px !important;
  height: 150px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.newNavbar{
  height: 90px;
  width: 100%;
  background-color: #1A305F;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px;
  
}
.navList{
  display: flex;
  align-items: center;
  gap: 20px;
}
.navLogo{
  display: flex;
  height: 90px;
  align-items: center;
  padding-right: 20px;
  border-right: 1px solid white;
}
@media (max-width:900px) {
  .navList{
    display: none;
  }
  .newNavbar{
    height: 70px;
    padding: 0px 10px;
  }
  .navLogo img{
    height: 35px;

  }
  .navLogo{
    border: none;
    padding-right: 10px;
  }
  .regButton{
    height: 30px;
    padding: 2px 10px;
  }
  .regButton3{
    height: 30px;
    padding: 2px 0px;
  }
  .homeHeader{
    flex-direction: column;
    height: auto;
  }
  
}


.container101{
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
  padding: 0 100px;
}
.regButton{
  border-radius: 5px;
  height: 40px;
  padding: 2px 25px;
  background-color: #1e64e7;
  border: none;
  color: white;
}
.regButton3{
  border-radius: 5px;
  height: 40px;
  padding: 2px 20px;
  background-color: #1e64e7;
  border: none;
  color: white;
}
.regButton2{

  height: 45px;
  padding: 2px 25px;
  background-color: #1e64e7;
  border: none;
  color: white;
}
.homeHeader{
  height: 450px;
  width: 100%;
  background-color: #f6f7fe;
  display: flex;
  justify-content: center;
}

.homeFooter{
  width: 100%;
  height: 180px;
  background-color: #00159d;
  display: flex;
  justify-content: center;
}
.footerComponent{
  width: 80%;
 
}
.container102{
  width: 50%;
}
.container01{
  width: 100%;
  display: flex;
  justify-content: center;
  height: 400px;
  margin-top: 50px;
}

.container05{
  width: 100%;
  display: flex;
  justify-content: center;
  height: 500px;
  margin-top: 50px;
}
.contentBox{
  display: flex;
  width: 80%;
  justify-content: center;
 
}

.contentBox006{
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
 
}
.contentBox008{
  display: flex;
  width: 100%;
  justify-content:space-between;
 
}
.contentBox02{
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
 padding-right: 100px;
}
.contentBox00{
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
 padding-left: 100px;
}
.imageContent{
  width: 40%;

}
.homeButton{
  border-radius: 5px;
  height: 40px;
  padding: 2px 25px;
  background-color: #1e64e7;
  border: none;
  color: white;
  width: 37%;
}
.container003{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 600px;
  background-color:#eaf0fd;
  align-items: center;
}
.content003{
  width: 80%;
  display: flex;
  justify-content: center;
}
.content004{
  width: 60%;
  display: flex;
}
.content005{
 width: 40%;
 background-color: white;
 height: 500px;
 padding: 20px;
 border-radius: 10px;
}

.container07{
  display: flex;
  height: 400px;
  justify-content: center;
  width: 100%;
}
.lowerContent{
  width: 30%;
}
.content99{
  width: 60%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.content88{
  width: 48%;
  height: 170px;
}
.button09{
  width: 46%;
  cursor: pointer;
  margin-top: 20px;
}
.content007{
  width: 50%;
}
.list009{
  display: none;
  position: absolute;
}
.button09 :hover + .list009{
  display: flex;
}
.footer001{
 height: 40%;
}
.headerImage{
  height: 450px;
  width: 100%;
}
.marginBox{
  margin-top: 0px;
}

@media (max-width:900px) {
  .contentBox02{
    width: 100%;
    padding-right: 0px;
  }
  .contentBox008{
    flex-direction: column;
  }
  .footer001{
    flex-direction: column;
    gap: 40px;
  }
  .footer002{
    flex-direction: column;
   
  }
  .content007{
    width: 90%;
  }
  .contentBox006{
    flex-direction: column;
    margin-top: 20px;
  }
  .headerImage{
    height: 250px;
    margin-top: 20px;
  }
  .boxgap{
    gap: 20px;
    justify-content: center;
    padding-left: 10px;
  }
  .button09{
    width: 100%;
    padding-left: 10px;
    margin-top: 0px;
  }
  .signText{
    font-size: 14px;
  }
  .content99{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .marginBox{
    margin-top: 30px;
  }
  .content88{
    width: 100%;
  }
  .contentBox00{
    padding-left: 0px;
    width: 100%;
  }
  .lowerContent{
    width: 100%;
  }
  .container07{
    height: auto;
    padding-bottom: 20px;
  }
  .container05{
    height: auto;
  }
  .marginBot{
    margin-bottom: 20px;
  }
  .homeFooter{
    height: auto;
  }
  .container003{
    height: auto;
  }
  .content005{
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
  .content004{
    width: 100%;
    flex-direction: column;
    padding: 20px 0px;
  }
  .content003{
    width: 95%;
    flex-direction: column;
  }
  .homeButton{
    width: 200px;
  }
  .imageContent{
    width: 100%;
    margin-top: 20px;
  }
  .container101{
    width: 100%;
    padding: 0px 10px;
   
  }
  .homeHeader{
    height: auto;
  }
  .footerComponent{
    width: 95%;
  }
  .container102{
    width: 100%;
    padding: 0px 10px;
    padding-left: 10px;
  }
  .container102 img{
    height: 300px;
  }
  .container01{
    height: auto;
  }
  .contentBox{
    width: 95%;
    display: flex;
    padding: 0px;
    flex-direction: column;
  }
}

.liDiv2{
  width: 230px !important;
  height: 420px !important;
  display: flex;
  justify-content: center;
 margin-right: 5px;
 border-radius: 15px;
}

.imgDiv{
  width: 86px;
  height: 55px;
}
.imgDiv2{
  width:230px;
  height:420px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

}

.like2{
  background-color: blue;
  padding: 5px 8px;
  border-radius: 40px;
}
.dislike2{
  background-color: rgb(45, 43, 43);
  padding: 5px 8px;
  border-radius: 40px;
}
 .like5{
  color: blue;
 }
 .dislike5{
  color: black;
 }

.reelSection{
  background-color: white;
  width: 100%;
  display: flex;
}

.reelSection2{
  display: none;
}
@media (max-width:900px) {
  .reelSection{
   display: none;
  }
  .reelSection2{
    visibility: visible;
    display: block;
   
    width: 100%;
    height: 100vh;
  }
}



.m-marketing__asFeaturedIn ul>li img {
  width: 86px;
  height: 55px;
  /* -o-object-fit: contain; */
  object-fit: contain;
}
.m-marketing__asFeaturedIn ul>li{
 width: 150px;
}

.sidebar-submenu2 {
  max-height: 0;
  overflow: hidden; /* Important for smooth transitions */
  transition: max-height 0.5s ease, opacity 0.5s ease; /* Smooth animation */
  opacity: 0;
}

/* When open, set the max-height to allow content to be visible */
.sidebar-submenu2.open {
  max-height: 200px; /* Adjust based on your content */
  opacity: 1; /* Make the content visible */
}

/* Adjusting list item styles as needed */
.sidebar-dropdown a {
  text-decoration: none;
  color: inherit;
}

.sidebar-dropdown a:hover {
  text-decoration: underline;
}

.accordion-arrow {
  transition: transform 0.3s ease; /* Smooth rotation transition */
}

/* Rotate the arrow when the accordion is open */
.accordion-arrow.rotated {
  transform: rotate(90deg); /* Rotate 90 degrees for open state */
}

/*home page css*/
@media only screen and (max-width: 425px) {
  .m-marketing__asFeaturedIn ul>li img {
      width: 30px!important;
      height: 55px;
      -o-object-fit: contain;
      object-fit: contain;
  }
  }

  .loginContentDiv{
    height: 92vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  @media only screen and (max-width: 800px) {
 
    .loginContentDiv{
      flex-direction: column;
      height: auto;
    }
    .box_shadow{
       display: flex;
       justify-content: center;
    }
    .loginContentDiv2{
      width: 100%!important;
    }
    .loginContentDivVideo{
      width: "100%" !important;
    }
    .loginContentDivVideo2{
        width: 100%;
    }

    .loginpageDiv{
      width: 100%;
      
    }
    .login-div{
      width: 100%;
    }
}


/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

/* ShareButtons.css */
.share-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.share-options {
  width: 400px;
  height: 300px;
  padding: 20px;
}



/* VerticalTabs.css */

.container555 {
  display: flex;
  height: auto;
  width: 100%;
}

.tabs555 {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
  width: 30%;
 padding: 15px;
}

.tab555 {
  padding: 10px;
  cursor: pointer;
  /* border-bottom: 1px solid #ddd; */
  text-align: left;
}

.tab555:hover {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.tab555.active {
  background-color: #EBF5FF;
  font-weight: 500;
  border-radius: 10px;
}

.tab555-panel {
  padding: 15px;
  flex-grow: 1;
}

.addPopup{
  width: 500px;
  height: auto;
  padding: 20px;
}

.search-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 23px;
  padding: 5px;
  flex: 1;
  

}

.search-container4 {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 23px;
  padding: 5px;
  flex: 1;
  

}

.search-container3 {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 23px;
  padding: 5px;
  flex: 1;
  

}

.search-icon {
  margin-right: 8px;
  color: #ccc;
  font-size: 22px;
}

.search-field {
  border: none;
  outline: none;
  flex: 1;
  height: 35px;
  padding: 0px;
  
}

.search-field::placeholder {
  color: #ccc;
}

.search-field:focus {
  border: none;
  background: transparent;
  outline: none;
}
.vtubeNav{
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
}
.vtubeLogo{
  height: 50px;
}
.SidebarButtonsHover{
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;
  width: 95%;
  cursor: pointer;
}
.SidebarButtonsHover :hover{
  background-color: rgb(228, 225, 225);
  border-radius: 8px;
}

#video{
 
  width: 100% !important;
  object-fit: contain;
}

@media (max-width:700px) {
  .sideBar{
      display: none;
  }
  #MenuBarLogo{
    display: none !important;
  }
  .SearchInput{
    display: none !important;
  }
  #SearchInputIcon{
    display: flex !important;
  }
  #Appbar{
    display: flex !important;
    
  }
  #video{
    height: 300px !important;
    z-index: 10 !important;
    border-radius: 0px !important;
  }
  #video2{
    height: 300px !important;
    z-index: 10 !important;
    border-radius: 0px !important;
  }
}

@media (max-width:660px) {
  .channelName_and_button{
    flex-direction: column !important;
  }
  #subscribe{
    margin-left: auto;
  }
  #buttons{
    gap:16px !important;
  }
  #Videodesc_in_mobileView{
    display: flex !important;
  }
  #Description_about_video_mobileView{
    display: flex !important;
  }
  #Description_about_video{
    display: none !important;
  }
  #videodesc_in_pc{
    display: none !important;
  }
  .fontname{
    font-size: 20px !important;
  }
 
}

  
@media (max-width:1070px) {

  .LargeSide{
    width: 170px !important;
  }
  #ActiveVideo{
    flex-direction: column;
  }
  #RecommendedVideos{
    max-width: 98% !important;
    margin: 0 auto !important;
  }
  #VideoDeatils{
    width: 98% !important;
    margin: 0 auto !important;
  }
  #video{
    height: 450px !important;
  }
  #video2{
    height: 450px !important;
  }
  #Video_Comments_in_mobile{
    display: flex !important;
  }
  #Videocomments{
    display: none;
  }
  #cardHolder{
    /* flex-direction: column; */
  }
}
#SingleChannel_hover :hover{
 background-color: #E5E5E5;
 cursor: pointer;
}
@media (max-width:900px) {

  #video{
    height: 350px !important;
  }
  #video2{
    height: 350px !important;
  }
}
@media (max-width:700px) {

#video{
  height: 300px !important;
}
#video2{
  height: 300px !important;
}
  #desc{
    margin-top: 290px !important;
  }
  #searchItems{
    position: absolute;
      top: 40px;
      left: -50px !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      padding: 0px;
      border: 1px solid rgb(242, 242, 242);
      background-color:white ;
      width: 98vw !important;
      right: 0 !important;
  }
  /* #searchedItem {
    background-color:white !important;
    cursor: pointer;
  } */
  #searchedItem :hover{
    background-color: rgb(242, 242, 242) !important;
    cursor: pointer;
  }
}
#searchItems{
  position:absolute;
  top:40px;left:0;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);border-radius:4px;width:100%;
  padding:0;
  border:1px solid #F2F2F2;
  background-color:#F2F2F2;
}
@media (max-width:600px) {

  .setStick2{
    margin-top: 0px !important;
  }
  .setting_tabs{
    min-height: auto !important;
  }
  .m-settingsV2__referralsLinks__copyableLink{
    margin: 0px !important;
    height: 70px !important;
  }
  .minds-groups-profile-conversation{
    display: none !important;
  }

  /* .central-meta{
    width: 95% !important;
    margin-left: 10px !important;
  } */
  .user-avatar1{
    left: -180px !important;
  }
  .feature-photo > figure img{
    height: 200px !important;
    margin-top: 27px !important;
  }
  .bgcover{
    padding: 0px !important;
  }
 
  .messengerPopup{
    bottom: 0px !important;
    top: 200px !important;
    width: 95% !important;
    right: 10px !important;
  }
  .feature-photo1{
   margin-top: 40px !important;
   margin-left: 10px !important;
   margin-right: 10px !important;
    height: auto !important;
  }
  .timeline-info > ul{
    padding: 0px 10px !important;
  }
  #votemain{
    margin-top: 0px !important;
  }

  .pepl-info{
    width: 100% !important;
  }
  .nearby-contct > li{
    padding: 10px !important;
  }
  .coment-area{
    padding: 0px !important;
  
  }
  .post-comt-box{
    width: 88% !important;
    margin-left: 10px;
  }
  .user-avatar > figure{
    left: 30px !important;
  
    width: 100% !important;
    height: auto !important;
  }

  #followlist{
    justify-content: right !important;
   
  }
 
  .user-avatar > figure > img{
    border: 5px solid white;
    height: 150px !important;
    width: 150px !important;
  }
  
  .feature-photo1 .container-fluid{
    padding: 0px 20px !important;
    height: auto !important;
  }
  #video{
    height: 250px !important;
    object-fit: cover;
    width: 85% !important;
  }
  #VideoDeatils {
    width: 100% !important;
  }
  #desc2{
    width: 95% !important;
    
  }
  #maindiv{
    display: flex;
   flex-direction: column;
   align-items: center;
  }

  #video2{
    height: 250px !important;
    object-fit: cover;
    width: 100% !important;
  }
  #desc{
    margin-top: 20px !important;
  }
  #Video_image{
    width: 100% !important;
    height: 250px !important;
  }
  #Recommended_Cards{
    flex-direction: column !important;
  }
  #recommended_desc{
    padding-top: 5px;
  }
  #Recommended_more{
    display: flex !important;
  }
  #Disable_more{
    display: none !important;
  }
  #UploadVideo_dialog{
    width: 95% !important;
  }
}
#mychannel:hover {
  display: flex !important;
}

.mychannelAvatar:hover #mychannel {
  display: flex !important;
}
#searchedItem :hover{
  background-color: white !important;
  cursor: pointer;
}

@media (max-width:449px) {
  #Description_about_video{
    /* height: 160px !important; */
  }
  #Video_image{
    width: 100% !important;
    height: 200px !important;
  }
}
