/* @import url('//raw.githubusercontent.com/necolas/normalize.css/master/normalize.css'); */

/*fonts-are-here */

@font-face {
    font-family: robo-light;
    src: url("../fonts/roboto/Roboto-Light.ttf");
}
@font-face {
    font-family: robo-medium;
    src: url("../fonts/roboto/Roboto-Medium.ttf");
}
@font-face {
    font-family: robo-bold;
    src: url("../fonts/roboto/Roboto-Bold.ttf");
}

@font-face {
    font-family: open-light;
    src: url("../fonts/openSans/OpenSans-Light.ttf");
}
@font-face {
    font-family: open-regular;
    src: url("../fonts/openSans/OpenSans-Regular.ttf");
}
/* @font-face { */
    /* font-family: open-semibold; */
    /* src: url("../fonts/openSans/OpenSans-Semibold.ttf"); */
/* } */
@font-face {
    font-family: open-bold;
    src: url("../fonts/openSans/OpenSans-Bold.ttf");
}


.rL{
    font-family: robo-light;
}
.rM{
    font-family: robo-medium;
}
.rB{
    font-family: robo-bold;
}
.oL{
    font-family: open-light;
}
.oR{
    font-family: open-regular;
}
.oS{
    font-family: open-semibold;
}
.oB{
    font-family: open-bold;
}
body{
    font-family: open-regular;
    overflow-x: hidden;
}

/*fonts-are-here */




/*--------------------------------------*/




/*mix-styles-are-here*/

body p,
body span{
    line-height: 20pt;
}

button{
    outline: none !important;
}
a{
    outline: none !important;
    text-decoration: none !important;
}
.p0{
    padding: 0;
}
.m0{
    margin: 0;
}
.mtb20{
    margin-top: 20px;
    margin-bottom: 20px;
}
.responsive{
    padding-top: 20px;
    padding-bottom: 20px;
}
.margin-minus{
    margin-top: -60px;
}
.centered{
    margin-left: auto;
    margin-right: auto;
    display: block;
}
/* .logo{
    padding-top: 10px;
    padding-bottom: 10px;
} */
.sectionP20{
    padding-top: 20px;
    padding-bottom: 20px;
}
.sectionP40{
    padding-top: 40px;
    padding-bottom: 40px;
}
.sectionP60{
    padding-top: 60px;
    padding-bottom: 60px;
}

p{
    font-size: 15px;
    color: #777;
}
a.g:hover{
    color: #fff;
}
.forgot{
    color: #aaa;
    margin-top: 10px;
    transition: all 0.3s;
}
.forgot:hover{
    color: #666;
    transition: all 0.3s;
}

.br{
    border-right: 1px solid #eee;
}
.heading-text{
    margin-bottom: 20px;
}
.heading-text span{
    font-size: 32px;
    font-family: robo-light;
}
.ul-style{
    padding-left: 0px;
    list-style: none;
}

/*mix-styles-are-here*/



/*--------------------------------------*/




/*colors-are-here*/

.white{
    color: #fff;
}
.light{
    color: #555;
}
.light2{
    color: #999;
}
.blue{
    color: #1f558d;
}
.blue-L{
    color: #3870b1e8;
}
.gold{
    color: #fff;
}
.gold-gradient-color{
    background:linear-gradient(to right, #fff, #fff);
    --webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s;
}
.blue-gradient-color{
    background: #fff;
    /*background:linear-gradient(to right, rgb(30,101,178) ,rgb(8,27,73));*/
    --webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s;
}

/*colors-are-here*/




/*--------------------------------------*/




/*backgrounds-are-here*/

.header-bg{
    background: url('/Victus-Token-react/bg_banner_img.png') center no-repeat;
    background-size: cover;
}
.blue-bg{
    background: rgba(9,29,73,0.97);
}
.gradient{
    background: -moz-linear-gradient(45deg, rgba(8,27,73,0.97) 0%, rgba(30,101,178,0.97) 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(8,27,73,0.97)), color-stop(100%, rgba(30,101,178,0.97))); /* safari4+,chrome */
    background: -webkit-linear-gradient(45deg, rgba(8,27,73,0.97) 0%, rgba(30,101,178,0.97) 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(45deg, rgba(8,27,73,0.97) 0%, rgba(30,101,178,0.97) 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(45deg, rgba(8,27,73,0.97) 0%, rgba(30,101,178,0.97) 100%); /* ie10+ */
    background: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%); /* w3c */
    overflow: hidden;
}
.gradient50{
    background: -moz-linear-gradient(45deg, rgba(8,27,73,0.9) 0%, rgba(30,101,178,0.9) 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(8,27,73,0.9)), color-stop(100%, rgba(30,101,178,0.9))); /* safari4+,chrome */
    background: -webkit-linear-gradient(45deg, rgba(8,27,73,0.9) 0%, rgba(30,101,178,0.9) 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(45deg, rgba(8,27,73,0.9) 0%, rgba(30,101,178,0.9) 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(45deg, rgba(8,27,73,0.9) 0%, rgba(30,101,178,0.9) 100%); /* ie10+ */
    background: linear-gradient(45deg, rgba(8,27,73,0.9) 0%, rgba(30,101,178,0.9) 100%); /* w3c */
    overflow: hidden;
}
.grey-bg{
    background: #f1f1f1;
}
.grey-bg-o{
    background: rgba(241,241,241,.97);
}
.counter-bg{
    background: url('../images/backgrounds/main-bg-2.png') left fixed;
    background-size: cover;
}
.hiw-bg{
    background: url('../images/backgrounds/main-bg.png') left fixed;
    background-size: cover;
}
.dark-bg{
    background: #0C1222;
}
.gold-gradient-bg{
    background: -moz-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -webkit-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -o-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -ms-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: linear-gradient(to right, #fff 0%, #FF6C0D 100%);
}

/*backgrounds-are-here*/




/*--------------------------------------*/




/*buttons-are-here*/

.info-btn{
    padding: 3px;
    border-radius: 3px;
    background: transparent;
}
.info-btn:hover{
    background: #eee;
}
.btn-gradient{
    color: #fff;
    font-family: robo-medium;
    font-size: 15px;
    border-radius: 30px;
    border: none;
    padding: 12px 30px;
    letter-spacing: .5px;
    margin-bottom: 15px;
    background: -moz-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -webkit-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -o-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -ms-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: linear-gradient(to right, #1c589b 0%, #194677 100%);
    transition: all 0.3s;
}
.btn-gradient:hover{
    color: #fff;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.5s;
}
.btn-gradient:focus{
    color: #fff;
}
.outline-button{
    padding: 2px;
}
.outline-button div{
    padding: 6px 30px;
    border-radius: 30px;
    font-size: 15px;
    font-family: open-regular;
    transition: all 0.3s;
}
.btn-gradient.outline-button div span{
    background:linear-gradient(to right, #fff, #FF6C0D);
    --webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s;
}
.btn-gradient.outline-button:hover div span{
    background:linear-gradient(to right, #fff, #FF6C0D);
    --webkit-background-clip: text;
    -webkit-text-fill-color: white;
    transition: all 0.5s;
}
.btn-gradient.outline-button2:hover{
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(253, 161, 14, 0.6);

}
.btn-gradient.outline-button:hover div{
    background: -moz-linear-gradient(left, #fff 0%, #FF6C0D 100%) !important;
    background: -webkit-linear-gradient(left, #fff 0%, #FF6C0D 100%) !important;
    background: -o-linear-gradient(left, #fff 0%, #FF6C0D 100%) !important;
    background: -ms-linear-gradient(left, #fff 0%, #FF6C0D 100%) !important;
    background:linear-gradient(to right, #1c589b 0%, #194677 100%) !important;
    color: #fff;
    transition: all 0.5s;
}
.btn-white-outline{
    color: #fff;
    font-family: robo-medium;
    font-size: 15px;
    border-radius: 30px;
    border: none;
    padding: 12px 30px;
    letter-spacing: .5px;
    margin-bottom: 15px;
    background: transparent;
    border: 2px solid #fff;
    transition: all 0.3s;
}
.btn-white-outline:hover{
    color: #fff;
    background: #fff;
    transition: all 0.5s;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
}

/*buttons-are-here*/




/*--------------------------------------*/



/*input-styles-are-here*/

input {
    width: 100%;
    display: block;
    border: none;
    /* padding: 20px 20px 20px 99px; */
    border-bottom: 1px solid #e9e9e9;
    -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    background: -webkit-linear-gradient(to top, rgba(255, 255, 255, 0) 98%, #fff 2%);
    /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 98%, #1c589b 2%); */
    background-position: -800px 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-family: open-light;
    font-size: 16px;
    color: #999;
}
/* input:valid {
    box-shadow: none;
    outline: none;
    background-position: 0 0;
    z-index: 9999999;
    color: #19487a;
    border-color: #19487a !important;
} */
input:focus{
    box-shadow: none;
    outline: none;
    background-position: 0 0;
    color: #1c589b;
    border-color:   #AE8A3D !important;
}
/* input:focus::-webkit-input-placeholder, input:valid::-webkit-input-placeholder {
    color: #1c589b;
    visibility: visible !important;
} */
.input-box{
    position: relative;
}
.input-box span{
    position: absolute;
    z-index: 5;
    top: 0;
    margin-top: 18px;
    font-size: 20px;
    left: 15px;
    color: #aaa;
    transition: all 0.3s;
}
/* .input-box input:focus + span{
    color: #1c589b;
    transition: all 0.5s;
} */
/* .input-box input:valid + span{
    color: #1c589b;
    transition: all 0.5s;
} */
/* textarea{
    width: 100%;
    display: block;
    border: none;
    padding: 20px 0 20px 59px;
    border-bottom: 1px solid #e9e9e9;
    -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 99%, #fff 1%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 99%, #fff 1%);
    background-position: -800px 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-family: open-light;
    font-size: 16px;
    color: #999;
} */
textarea:focus{
    box-shadow: none;
    outline: none;
    background-position: 0 0;
    /* color: #fff;
    border-color: #fff !important; */
}
/* textarea:focus::-webkit-input-placeholder, input:valid::-webkit-input-placeholder {
    color: #fff;
    visibility: visible !important;
} */
.input-box textarea:focus + span{
    color: #fff !important;
    transition: all 0.5s;
}

/*form-styles-are-here*/




/*--------------------------------------*/




/*navbar-styling-is-here*/

.navbar-default{
    background: transparent;
    border: none;
    position: fixed !important;
    width: 100%;
    /*border-bottom: 1px solid #e9e9e9;*/
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: none;
    z-index: 999;
}
.navbar-nav.navbar-right > li{
    padding-top: 20px;
    padding-bottom: 0px;
    margin-left: 1px;
}
.navbar-nav.navbar-right > li > a{
    padding: 10px 20px;
    color: #fff;
    font-family: open-regular;
    letter-spacing: 1px;
    font-size: 15px;
    border-radius: 3px;
}
.navbar-nav.navbar-right > li > a:focus{
    background: transparent;
    color: #fff;
}
.navbar-nav.navbar-right > li > a:hover{
    background: transparent;
    color: #fff;
}
.navbar-nav.navbar-right > li > a.active{
    background: transparent;
    color: #fff;
}
.navbar-nav.navbar-right > li > a.active:hover{
    background: transparent;
    color: #fff;
}
.navbar-nav.navbar-right > li.gold{
    padding-top: 12px;
}
.navbar-nav.navbar-right > li.gold > a{
    color: #FC7C11;
}
.navbar-nav.navbar-right > li.gold > a:hover{
    color: #fff;
    background: transparent;
}
.navbar-brand {
    float: left;
    height: auto;
    padding-top: 11px;
}
.navbar-toggle{
    margin-top: 25px;
}
.navbar-collapse.collapse.in{
    max-height: 80vh !important;
    overflow-y: scroll;
}

.nav-justified{
    border: none;
}
.nav-tabs.nav-justified > li > a{
    border-radius: 0;
    border-color: #eee;
    border-bottom: none !important;
    padding-top: 25px;
    padding-bottom: 25px;
    background: transparent;
    color: #6011b1;
    font-family: open-regular;
    font-size: 16px;
}
.nav-tabs.nav-justified > li > a:hover{
    background-color: #fff;
}
.nav-tabs.nav-justified > li.active{
    background-color: #fff;
    border-color: #fff !important;
}
.nav-tabs.nav-justified > li.active > a:hover{
    color: #6011b1;
}
.nav-fixed{
    position: fixed;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(8, 27, 73, 0.6);
    background: -moz-linear-gradient(45deg, rgba(8,27,73,0.97) 0%, rgba(30,101,178,0.97) 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(8,27,73,0.97)), color-stop(100%, rgba(30,101,178,0.97))); /* safari4+,chrome */
    background: -webkit-linear-gradient(45deg, rgba(8,27,73,0.97) 0%, rgba(30,101,178,0.97) 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(45deg, rgba(8,27,73,0.97) 0%, rgba(30,101,178,0.97) 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(45deg, rgba(8,27,73,0.97) 0%, rgba(30,101,178,0.97) 100%); /* ie10+ */
    /*background: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%); *//* w3c */
    background:#0a1538;
}

/*navbar-styling-is-here*/



/*--------------------------------------*/



/*slider-style*/

#slider-owl .item{
    background-size:cover;
    background-position:center top;
    width: 100%;
    height: 100%;
}
/*slider-style*/



/*--------------------------------------*/




/*why-choose-us-styles-are-here*/

.video-embed{
    width: 400px;
    height: 300px;
    border-radius: 8px;
    margin-bottom: 80px;
    cursor: pointer;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(8, 27, 73, 0.6);
}
.video-embed .thumb span{
    font-size: 40px;
    text-align: center;
    margin-top: 120px;
    display: block;
    transition: all 0.3s;
}
.video-embed .thumb{
    background: url('../images/video.png');
    background-size: cover;
    width: 400px;
    height: 300px;
    position: absolute;
    border-radius: 8px;
}
.video-embed iframe{
    width: 100%;
    height: 300px;
    border-radius: 8px;
}
.quote-div{
    border-radius: 6px;
    max-width: 400px;
    padding: 10px 30px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(253, 161, 14, 0.8);
    position: absolute;
    right: -20px;
    bottom: 20px;
}
.quote-div p{
    line-height: 23px;
    font-size: 14px;
}
.video-embed:hover .thumb span i{
    -webkit-text-fill-color: #fff;
    transition: all 0.5s;
}

/*why-choose-us-styles-are-here*/



/*--------------------------------------*/



/*acordian-styling-here*/

.acordian{
    background: #fff;
    border-radius: 6px;
    margin-bottom: 12px;
    padding: 3px;
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: background 0.3s ease-out;
    -moz-transition: background 0.3s ease-out;
    -o-transition: background 0.3s ease-out;
    transition: background 0.3s ease-out;
}
.active.gradient-accordian{
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(253, 161, 14, 0.6);
    background: -moz-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -webkit-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -o-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -ms-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: linear-gradient(to right, #fff 0%, #FF6C0D 100%);
    -webkit-transition: background 0.3s ease-in;
    -moz-transition: background 0.3s ease-in;
    -o-transition: background 0.3s ease-in;
    transition: background 0.3s ease-in;
}
/*.acordian:hover{
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(253, 161, 14, 0.6);
    background: -moz-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -webkit-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -o-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -ms-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: linear-gradient(to right, #fff 0%, #FF6C0D 100%);
    -webkit-transition: background 0.3s ease-in;
    -moz-transition: background 0.3s ease-in;
    -o-transition: background 0.3s ease-in;
    transition: background 0.3s ease-in;
}*/


.acordian .acordian-desc{
    padding-top: 18px;
    overflow: hidden;
    padding-bottom: 10px;
}
.acordian .acordian-desc i{
    font-size: 32px;
    margin-right: 15px;
    background:linear-gradient(to right, #fff, #FF6C0D);
    --webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s;

}
.acordian.active.gradient-accordian .acordian-desc i{
    -webkit-text-fill-color: white;
    transition: all 0.5s;
}
.acordian .acordian-desc span{
    font-size: 16px;
    margin: 0;
    margin-top: 3px;
    color: #999;
    position: relative;
    top: -6px;
    text-transform: uppercase;
    transition: all 0.3s;
}
.acordian.active.gradient-accordian .acordian-desc span{
    color: #fff;
    transition: all 0.5s;
}
.acordian .acordian-body{
    opacity: 0;
    border-top: 1px solid rgba(255,255,255,0.1);
    max-height: 0px;
    overflow: hidden;
    transition: all .2s ease-out;
}
.acordian.active.gradient-accordian .acordian-body span{
    font-size: 14.5px;
}
.acordian.active.gradient-accordian .acordian-body{
    opacity: 1;
    max-height: 200px;
    padding-top: 15px;
    padding-bottom: 15px;
    transition: all .3s ease-in;
}

/*accordian-styling-here*/



/*--------------------------------------*/



/*services-div-is-here*/

.services-div{
    background: #fff;
    border-radius: 10px;
    margin-bottom: 60px;
    box-shadow: 0px 0px 35px rgba(255,142,3,.12);
    overflow: hidden;
}
.services-div .br{
    border-right: 1px solid #f1f1f1;
}
.services-div .service{
    padding: 20px 10px;
}
.services-div .service-icon{
    font-size: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.services-div .service-icon i{
    background:linear-gradient(to right, #fff, #FF6C0D);
    --webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.services-div .service-hover{
    background: #fff;
    transition: all 0.3s;
}
.services-div .service-hover:hover{
    background: rgba(253,161,14,0.08);
    transition: all .5s;
}

/*services-div-is-here*/


/*service2--styles--are--here*/

.service2{
    padding: 20px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s;
}
.service2 .service2-icon{
    font-size: 45px;
    margin-bottom: 15px;
}
.service2 .service2-desc{
    padding-right: 15px;
}
.service2:hover{
    background: #fff;
    box-shadow: 0px 2px 20px rgba(0,0,0,0.1);
    transition: all 0.5s;
}

/*service2--styles--are--here*/





/*features-styles-are-here*/

.features .ul-style li{
    background: url('../images/tick.png');
    background-repeat: no-repeat;
    padding-left: 30px;
    margin-bottom: 15px;
}

/*features-styles-are-here*/


/*--------------------------------------*/




/*counter-styles-are-here*/

.counter-bg .br{
    border-right: 1px solid rgba(255,255,255,0.1);
}
.counter-bg .bb{
    border-bottom: 1px solid rgba(255,255,255,0.1);
}
.counter-bg .numbers{
    padding: 20px 0px;
}
.counter-bg .numbers span{
    font-size: 52px;
    text-shadow: 0px 3px 7px rgba(0,0,0,.25);
    line-height: normal;
}
.counter-bg .numbers p{
    letter-spacing: 2px;
    text-transform: uppercase;
}

/*counter-styles-are-here*/




/*--------------------------------------*/




/*team-styles-are-here*/

.team-member{
    border-radius: 7px;
    margin-top: 15px;
    cursor: pointer;
    max-width: 280px;
    margin-bottom: 20px;
    transition: all 0.2s;
}
.team-member.tm1{
    background: url('../images/team/1.png');
    background-size: cover;
}
.team-member.tm2{
    background: url('../images/team/2.png');
    background-size: cover;
}
.team-member.tm3{
    background: url('../images/team/3.png');
    background-size: cover;
}
.team-member.tm4{
    background: url('../images/team/4.png');
    background-size: cover;
}
.team-member .member-desc{
    min-height: 350px;
    padding: 40px 15px;
    text-align: center;
    border-radius: 7px;
    opacity: 0;
    background: -moz-linear-gradient(45deg, rgba(30,101,178,0.94) 0%, rgba(8,27,73,0.94) 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(8,27,73,0.94)), color-stop(100%, rgba(8,27,73,0.94))); /* safari4+,chrome */
    background: -webkit-linear-gradient(45deg, rgba(30,101,178,0.94) 0%, rgba(8,27,73,0.94) 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(45deg, rgba(30,101,178,0.94) 0%, rgba(8,27,73,0.94) 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(45deg, rgba(30,101,178,0.94) 0%, rgba(8,27,73,0.94) 100%); /* ie10+ */
    background: linear-gradient(45deg, rgba(30,101,178,0.94) 0%, rgba(8,27,73,0.94) 100%); /* w3c */
    transition: all 0.3s;
}
.team-member .member-desc p{
    font-size: 22px;
    text-transform: uppercase;
}
.team-member .member-desc small{
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #ccc !important;
    display: block;
}
.team-member .member-desc .social-icons{
    margin-top: 20px;
    margin-bottom: 10px;
}
.team-member .member-desc .social-icons a{
    margin-left: 5px;
    margin-right: 5px;
}
.team-member .member-desc .social-icons a i{
    font-size: 18px;
    background:linear-gradient(to right, #fff, #fff);
    --webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255,255,255,.075);
    transition: all 0.3s;
}
.team-member .member-desc .social-icons a:hover i{
    background:linear-gradient(to right, #fff, #FF6C0D);
    --webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.35s;
}
.team-member:hover{
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(8, 27, 73, 0.6);
    margin-top: 0px;
    margin-bottom: 35px;
    transition: all 0.35s;
}
.team-member:hover .member-desc{
    opacity: 1;
    transition: all 0.35s;
}

/*team-styles-are-here*/



.header-pad {
    padding-top: 120px;
    padding-bottom: 54px;
}
.header-text p{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 17px;
    font-weight: 600;
}



/*testimonial-styles-are-here*/

.testi-text{
    background: rgba(255,255,255,0.02);
    border-radius: 7px;
    padding: 30px;
    border: 1px solid #fff;
    cursor: pointer;
    text-align: center;
}
.testi-text p{
    font-size: 16px;
}
.testi-text span{
    font-size: 14px;
    text-transform: uppercase;
}
.testi-text small{
    display: block;
    text-transform: lowercase;
}
.testi-text img{
    margin-top: 10px;
    margin-bottom: 10px;
}
.testi-p-image .person-image div{
    border-radius: 50%;
    padding: 3px;
    border: 1px solid #fff;
}
.testi-p-image .person-image div{
    border-radius: 50%;
    padding: 4px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 88px;
    height: 88px;
    border: 1px solid #fff;
}
.testi-p-image .arrow-image img{
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.testi-p-image .person-image img{
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 50%;
}
#testimonial .owl-controls{
    display: none !important;
}

/*testimonial-styles-are-here*/


/*pricing-tables-styles-are-here*/

.package {
    box-sizing: border-box;
    max-width: 310px;
    margin-top: 30px;
    border: 1px solid #eee;
    border-radius: 8px;
    margin: 0 auto;
    padding: 24px;
    text-align: center;
    -webkit-transition: margin-top 0.5s linear;
    transition: margin-top 0.5s linear;
    position: relative;
    margin-right: 11px;
}
.package:hover {
    margin-top: 0px;
    -webkit-transition: margin-top 0.3s linear;
    transition: margin-top 0.3s linear;
}
.package .name {
    color: #777;
    font-weight: 300;
    font-size: 3rem;
    margin-top: -5px;
}
.package .price {
    margin-top: 7px;
    font-weight: bold;
}
.package .price::after {
    content: " / month per user";
    font-weight: normal;
}
.package .lineDiv{
    background-color: #dedede;
    border: none;
    height: 1px;
}
.package .trial{
    font-size: 12px;
    font-weight: 600;
    padding: 2px 21px 2px 21px;
    color: #fff;
    border: 1px solid #eee;
    font-family: open-regular;
    display: inline-block;
    border-radius: 15px;
    background: #fff;
    /*background: -moz-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -webkit-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -o-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: -ms-linear-gradient(left, #fff 0%, #FF6C0D 100%);
    background: linear-gradient(to right, #fff 0%, #FF6C0D 100%);*/
    position: relative;
    bottom: -10px;
}
.package ul {
    list-style: none;
    padding: 0;
    text-align: left;
    margin-top: 29px;
}
.package li {
    margin-bottom: 15px;
}
.package .checkIcon {
    font-family: "FontAwesome";
    content: "\f00c";
}
.package li::before {
    font-family: "FontAwesome";
    content: "\f00c";
    font-size: 2.2rem;
    color: #fff;
    margin-right: 3px;
}
.package.brilliant li::before {
    font-family: "FontAwesome";
    content: "\f00c";
    font-size: 2.2rem;
    color: #fff;
    margin-right: 3px;
}
.package.brilliant {
  background: -moz-linear-gradient(left, #fff 0%, #FF6C0D 100%);
  background: -webkit-linear-gradient(left, #fff 0%, #FF6C0D 100%);
  background: -o-linear-gradient(left, #fff 0%, #FF6C0D 100%);
  background: -ms-linear-gradient(left, #fff 0%, #FF6C0D 100%);
  background: linear-gradient(to right, #fff 0%, #FF6C0D 100%);
  border-color: #fff;
}
.package.brilliant .name,
.package.brilliant .price,
.package.brilliant ul li{
  color: #fff;
}
 /* {
    margin-top: 7px;
    font-weight: bold;
} */
.package .btn{
  margin-top: 15px;
  padding: 8px 30px;
}
.package .btn-white-outline{
  padding: 6px 30px;
}
/* Triangle */
/*.package.brilliant::before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 64px 64px 0 0;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
}
.package.brilliant::after {
    font-family: "FontAwesome";
    content: "\f00c";
    color: white;
    position: absolute;
    left: 9px;
    top: 6px;
    font-size: 1.5rem;
}*/

/*pricing-tables-styles-are-here*/


/*login-div-styles-are-here*/

.login-div {
    border-radius: 10px;
    background: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
    /* width: 384px; */
    display: block;
    background-color: #fcfcfc;
    padding: 58px 15px;
    -webkit-clip-path: polygon(0 0,100% 8px,99% 95%,0 98%);
    clip-path: polygon(0 0,100% 8px,99% 95%,0 98%);
    margin-top: 20px;
    max-width: 380px;
    overflow: hidden;
    box-shadow: 0px 2px 25px rgba(0,0,0,0.25);
}
.login-div .head{
    padding-left: 20px;
}
.login-div .head h3{
    font-family: robo-medium;
    color: #174583;
    font-size: 20px;
}
.login-div .head p{
    font-family: open-regular;
    color: #aaa;
    font-size: 14px;
}
.login-div .foot{
    padding: 15px 20px;
}
.login-div .body{
    margin-top: 42px;
    margin-bottom: 12px;
    border-top: 1px solid #e9e9e9;
}
.login-div .btn-gradient:hover{
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(253, 161, 14, 0.6);

}

/*login-div-styles-are-here*/



/*-------------------------------------*/




/*footer-styles-are-here*/

footer .company-desc a img{
    margin-bottom: 15px;
}
footer .company-desc p{
    font-size: 15px;
    margin-bottom: 20px;
}
footer .meet-us{
    margin-bottom: 20px;
}
footer .meet-us p{
    font-size: 25px;
    margin-bottom: 10px;
}
footer .meet-us span{
    font-size: 14px;
}
footer .cont-us{
    margin-bottom: 20px;
}
footer .cont-us p{
    font-size: 25px;
    margin-bottom: 10px;
}
footer .cont-us a{
    font-size: 14px;
    clear: both;
}
footer .border-right{
    border-right: 1px solid rgba(255,255,255,0.05);
}

/*footer-styles-are-here*/




/*-------------------------------------*/




/*scroll-bar-atyle-are-here*/

.nicescroll-rails div{
    width: 5px !important;
    height: 25px;
    border-radius: 2px;
    border: none !important;
    background: -moz-linear-gradient(top, #fff 0%, #FF6C0D 100%);
    background: -webkit-linear-gradient(top, #fff 0%, #FF6C0D 100%);
    background: -o-linear-gradient(top, #fff 0%, #FF6C0D 100%);
    background: -ms-linear-gradient(top, #fff 0%, #FF6C0D 100%);
    background: linear-gradient(to bottom, #1c589b 0%, #1c589b 100%);
}

.box_shadow{
    filter: drop-shadow(-1px 0 8px rgba(50,50,0,.5));
}
.pad_bottom_zero{
    padding-bottom: 0px;
}
/*scroll-bar-atyle-are-here*/




/*google-map-styles-are-here*/

.map-container {
    width: 100%;
}
.map-container iframe{
    width: 100%;
    display: block;
    pointer-events: none;
    position: relative; /* IE needs a position other than static */
}
.map-container iframe.clicked{
    pointer-events: auto;
}

/*google-map-styles-are-here*/


/*back-to-button-style-is-here*/

#myBtn{
    display: none;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 99;
    opacity: .5;
}
#myBtn:hover{
    opacity: 1;
}

/*back-to-button-style-is-here*/



/*loader-styles-are-here*/

.no-js #loader { display: none;  }
.js #loader { display: block; position: absolute; left: 100px; top: 0; }
.se-pre-con {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background: url('../images/preloader-small.gif') center no-repeat #fff;
}

/*loader-styles-are-here*/



/*pop-register-styles-are-here*/

#pop-register .modal-dialog{
    top: 40px;
    max-width: 340px;
    margin-bottom: 20px;
}
#pop-register .modal-content{
    border-radius: 10px;
}
#pop-register .modal-header{
    border-color: #e9e9e9;
}
#pop-register .modal-footer{
    border: none;
}
/*pop-register-styles-are-here*/


/*rest-pop-ups-styles-are-here*/

#pop-about .modal-header,
#pop-about .modal-footer,
#pop-read-more1 .modal-header,
#pop-read-more1 .modal-footer,
#pop-read-more2 .modal-header,
#pop-read-more2 .modal-footer,
#pop-read-more3 .modal-header,
#pop-read-more3 .modal-footer,
#pop-read-more4 .modal-header,
#pop-read-more4 .modal-footer{
    border: none;
}

/*rest-pop-ups-styles-are-here*/
/*home page*/

.m-marketing__image img.m-marketing__image--1 {
    -o-object-fit: cover;
    object-fit: fill;
    /* width: 400px;
    height: 320px; */
    -webkit-clip-path: polygon(0 1%,0 100%,100% 96%,100% 0);
    clip-path: polygon(0 1%,0 100%,100% 96%,100% 0);
    border-radius: 30px;
}
.m-marketing__image span:not(.m-marketing__imageUX):not(.m-marketing__imageTick):after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
     
    transform: translate(50px,50px);
    background: url(https://www.minds.com/static/assets/marketing/deco_4.svg) no-repeat;
    z-index: -1;
}


    .m-marketing__asFeaturedIn {
    margin: 78px 0px!important;
    
}

/* .col-lg-2{
    max-width: 15.6666% !important;
} */

.company_icon {
    /*background: #e9e9e9;*/
    padding: 1px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #174583;
    
}

.company_icon2 {
    /*background: #e9e9e9;*/
    padding: 1px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #174583;
    
}
.m-marketing__asFeaturedIn {
    width: 90%;
}
.m-grid {
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-column-gap: 20px;
    grid-row-gap: 0;
}
.m-homepage__appButtons>a img {
    width: 100%;
    min-width: 100px;
}
.m-homepage__appButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 120px;
}
.m-homepage__appButtons>a:first-child {
    margin-right: 5%;
}
.m-homepage__appButtons>a {
    width: 120px;
    height: 37px;
}
.sectionP60 h1 {
    font-size: 55px;
    font-weight: 900;
    font-family: Roboto,Helvetica,sans-serif;
}

@media (max-width:600px) {
    .sectionP60 h1{
        font-size: 40px;
        font-weight: 800;
        text-align: center;
    }
}
.m-marketingAsFeaturedIn__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.m-grid .m-grid__column-2 {
    grid-column: auto/span 2;
}
.m-marketingAsFeaturedIn__title {
    color: #888;
    font-size: 14px;
}
/* .m-grid__column-10 {
    grid-column: auto/span 10;
} */
.m-marketing__asFeaturedIn ul {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
}

.m-marketing__quotation h3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 37px;
    margin-bottom: 0;
}

.m-marketing__asFeaturedIn ul>li img {
    width: 86px;
    height: 55px;
    /* -o-object-fit: contain; */
    object-fit: contain;
}
.m-marketing__asFeaturedIn ul>li{
   width: 150px;
}
.Features-sektion {
    padding: 0px 0px 110px 0px;
}
.feature-box {
    padding: 9px 0px;
    font-size: 30px;
}

/*home page css*/
@media only screen and (max-width: 425px) {
.m-marketing__asFeaturedIn ul>li img {
    width: 30px!important;
    height: 55px;
    -o-object-fit: contain;
    object-fit: contain;
}
}
#particles canvas{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.time_line_ago{
    color: #999;
    float: left;
    font-size: 12px;
    width: 100%;
}

.form-container .input-field {
    border: 2px solid #67676B;
    border-radius: 5px;
    padding: 10px 10px;
    background-color: #18181B !important;
    color: #fff;
    width: 100%;
    margin: 10px 0px;
}

.form-container input{
    background-color: #18181B !important;
}




